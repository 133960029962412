import './App.css';
import { useCallback, useEffect, useState } from 'react';
import TechLogo from './TechLogo';

const techPortalURL = 'https://ww5.autotask.net/ClientPortal/Login.aspx?ci=504269&accountId'
const whatWeDoPageURL = 'https://www.pfsonline.com/what-we-do/technology'

const ContactTable = () => (
  <table>
    <tbody>
      <tr>
        <td>Phone: </td>
        <td><a href="tel:+17147063900">714.706.3900</a></td>
      </tr>
      <tr>
        <td>Email: </td>
        <td><a href='mailto:techstaff@pfsonline.com'>TechStaff@pfsonline.com</a></td>
      </tr>
      <tr>
        <td>Portal: </td>
        <td><a href={techPortalURL}>Portal Login Page</a></td>
      </tr>
    </tbody>
  </table>
)

const LaunchingHawksoft = () => (
  <div>
    <p>Launching Hawksoft...<br />
      if you do not see a Hawksoft window, then the phone number was not found in the CMS<br />
      Feel free to close this window.</p>
  </div>
)

function App() {
  const [phone, setPhone] = useState(null);

  useEffect(() => {
    if (phone) {
      const url = `hawksoft://${phone}/`;
      window.location = url;
    } else {
      const queryParams = new URLSearchParams(window.location.search)
      const phoneQuery = queryParams.get("phone")

      if (!phoneQuery) return;

      setPhone(phoneQuery);
    }
  }, [phone]);

  const handleLogoClick = useCallback(() => {
    if (phone) {
      // Go back to the "home page" by removing phone
      window.location = '/'
    } else {
      window.open(whatWeDoPageURL, '_blank')
    }
  }, [phone])

  const handleSubmit = useCallback((e) => {
    e.preventDefault(); // Prevent the form from refreshing the page
    const phoneValue = e.target.phone.value;
    setPhone(phoneValue);
  }, []);

  const PhoneField = () => (
    <form onSubmit={handleSubmit}>
      <label htmlFor="phone">Enter the client phone number:</label>
      <input type="text" id="phone" name="phone" />
      <button type="submit">Launch Hawksoft</button>
    </form>
  );

  return (
    <div className="App">
      <div className='Header' onClick={handleLogoClick} >
        <TechLogo />
      </div>
      <div className='Container'>
        <h1>Hawksoft ScreenPop</h1>
        <p>
          This website serves as an integration between our Hawksoft CMS and
          our phone system services. If you need any help, please reach out to us via:
        </p>

        <ContactTable />
        <br /><br /><br />

        {phone ? (
          <LaunchingHawksoft />
        ) : (
          <PhoneField />
        )}
      </div>
    </div>
  );
}

export default App;
